import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading-wrapper",
    { attrs: { loading: _vm.showLoading, size: 15 } },
    [
      _c(
        "span",
        [
          _c(
            VTooltip,
            {
              attrs: {
                disabled: !_vm.enableTooltip,
                bottom: "",
                "max-width": "150"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        VChip,
                        _vm._g(
                          {
                            staticClass: "ma-2 fit-content",
                            attrs: {
                              color: _vm.color,
                              "text-color": "black",
                              small: "",
                              disabled: ""
                            }
                          },
                          on
                        ),
                        [
                          _c(VIcon, { attrs: { left: "", small: "" } }, [
                            _vm._v(_vm._s(_vm.icon))
                          ]),
                          _c("span", { staticClass: "status-text" }, [
                            _vm._v(_vm._s(_vm.statusText))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
          ),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }