<template>
  <v-flex xs12 v-if="display">
    <v-layout align-baseline class="hover" @click.stop="goBrand" v-if="globalMenuList">
      <v-flex xs5>
        <span :id="'item-label-' + index" class="Body-1-Selected-On-Surface-High-Emphasis-Left">{{
          textLabel
        }}</span
        >&nbsp;
      </v-flex>
      <v-flex xs5>
        <menu-status-chip :menuId="recentMenu && recentMenu.id" :hideUser="true" class="status" />
      </v-flex>
    </v-layout>

    <v-layout
      align-baseline
      class="hover"
      @click.stop="isGlobalMod ? goGlobalMods() : goGlobalMenu()"
      v-else-if="!localBrand"
    >
      <v-flex xs5>
        <span
          :id="isGlobalMod ? 'global-mod-label' : 'global-menu-label'"
          class="Body-1-Selected-On-Surface-High-Emphasis-Left"
          >{{ isGlobalMod ? 'Global Modifier Groups' : 'Global Menu' }}</span
        >&nbsp;
      </v-flex>
      <v-flex xs5>
        <menu-status-chip :menuId="recentMenu && recentMenu.id" class="status" />
      </v-flex>
    </v-layout>

    <v-layout align-baseline class="hover" @click.stop="goLocalMenu" v-else>
      <v-flex xs5>
        <span
          :id="'local-menu-label-' + index"
          class="Body-1-Selected-On-Surface-High-Emphasis-Left"
          >{{ textLabel }}</span
        >&nbsp;
      </v-flex>
      <v-flex xs5>
        <menu-status-chip :menuId="recentMenu && recentMenu.id" class="status" />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import MenuStatusChip from '@/components/menu-status-chip';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { 'menu-status-chip': MenuStatusChip },
  props: ['record', 'search', 'localBrand', 'globalMenuList', 'isGlobalMod', 'index'],
  data: () => ({
    dialog: false,
    localMenu: null,
    toBeLinked: [],
    loading: false,
    display: true,
  }),
  computed: {
    ...mapGetters('menus', ['getCompanyMenuRecent']),
    ...mapGetters('sectors', ['getCompany']),
    ...mapState('adminPanel', ['user']),
    recentMenu() {
      const companyId = this.localBrand ? this.record.company : this.record.id;
      const recentMenu = this.getCompanyMenuRecent(
        companyId,
        this.localBrand ? this.record.id : undefined,
      );
      return recentMenu && recentMenu.id ? recentMenu : null;
    },
    textLabel() {
      if (this.localBrand && this.record.name) {
        return `${this.record.location.name} - ${this.record.location_description || ''}`;
      }
      return this.record && this.record.name;
    },
  },
  methods: {
    goBrand() {
      this.$router.push({
        name: 'brand',
        params: {
          sector_id: this.record.sector,
          company_id: this.record.id,
        },
      });
    },
    goGlobalMenu() {
      this.$router.push({
        name: 'menu-global',
        params: {
          company_id: this.record.id,
        },
      });
    },
    goLocalMenu() {
      this.$router.push({
        name: 'menu-local',
        params: {
          brand_id: this.record.id,
          company_id: this.record.company,
        },
      });
    },
    goToLocalBrands() {
      this.$router.push({
        name: 'local-brands',
        params: {
          sector_id: this.record.sector,
          company_id: this.record.id,
        },
      });
    },
    goGlobalMods() {
      this.$router.push({
        name: 'global-mods',
        params: {
          company_id: this.record.id,
        },
      });
    },
  },
  watch: {
    search(value = '') {
      if (!value) {
        this.display = true;
        return;
      }
      value = value.toString().toLowerCase();
      const should_show = this.record.name.toLowerCase().includes(value);
      this.display = should_show;
    },
  },
};
</script>

<style scoped>
.hover {
  cursor: pointer;
}
.hover:hover {
  background-color: #eff8ff;
}
.hover:hover > div > span {
  color: #0d73d8;
}
.hover:hover > span {
  color: #0d73d8;
}
.indent {
  padding-left: 55px;
}
.status {
  margin-left: 1em;
}
</style>
