import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _vm.globalMenuList
            ? _c(
                VLayout,
                {
                  staticClass: "hover",
                  attrs: { "align-baseline": "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goBrand($event)
                    }
                  }
                },
                [
                  _c(VFlex, { attrs: { xs5: "" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Body-1-Selected-On-Surface-High-Emphasis-Left",
                        attrs: { id: "item-label-" + _vm.index }
                      },
                      [_vm._v(_vm._s(_vm.textLabel))]
                    ),
                    _vm._v(" \n    ")
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs5: "" } },
                    [
                      _c("menu-status-chip", {
                        staticClass: "status",
                        attrs: {
                          menuId: _vm.recentMenu && _vm.recentMenu.id,
                          hideUser: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : !_vm.localBrand
            ? _c(
                VLayout,
                {
                  staticClass: "hover",
                  attrs: { "align-baseline": "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.isGlobalMod ? _vm.goGlobalMods() : _vm.goGlobalMenu()
                    }
                  }
                },
                [
                  _c(VFlex, { attrs: { xs5: "" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Body-1-Selected-On-Surface-High-Emphasis-Left",
                        attrs: {
                          id: _vm.isGlobalMod
                            ? "global-mod-label"
                            : "global-menu-label"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.isGlobalMod
                              ? "Global Modifier Groups"
                              : "Global Menu"
                          )
                        )
                      ]
                    ),
                    _vm._v(" \n    ")
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs5: "" } },
                    [
                      _c("menu-status-chip", {
                        staticClass: "status",
                        attrs: { menuId: _vm.recentMenu && _vm.recentMenu.id }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                VLayout,
                {
                  staticClass: "hover",
                  attrs: { "align-baseline": "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goLocalMenu($event)
                    }
                  }
                },
                [
                  _c(VFlex, { attrs: { xs5: "" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Body-1-Selected-On-Surface-High-Emphasis-Left",
                        attrs: { id: "local-menu-label-" + _vm.index }
                      },
                      [_vm._v(_vm._s(_vm.textLabel))]
                    ),
                    _vm._v(" \n    ")
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs5: "" } },
                    [
                      _c("menu-status-chip", {
                        staticClass: "status",
                        attrs: { menuId: _vm.recentMenu && _vm.recentMenu.id }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }