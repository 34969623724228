<template>
  <loading-wrapper :loading="showLoading" :size="15">
    <span>
      <v-tooltip :disabled="!enableTooltip" bottom max-width="150">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            class="ma-2 fit-content"
            :color="color"
            text-color="black"
            small
            disabled
          >
            <v-icon left small>{{ icon }}</v-icon>
            <span class="status-text">{{ statusText }}</span>
          </v-chip>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
      <slot></slot>
    </span>
  </loading-wrapper>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import { mapGetters, mapState } from 'vuex';
import { VChip, VTooltip } from 'vuetify/lib';

export default {
  components: {
    VChip,
    VTooltip,
  },
  computed: {
    ...mapGetters('menus', ['isMenuLocked']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['menus']),
    ...mapState('users', ['usersMapNames']),
    isLocked() {
      return this.isMenuLocked(this.menuId);
    },
    color() {
      return this.isLocked ? '#FFF59D' : 'gray';
    },
    dateTime() {
      return this.menu && this.menu.date
        ? DateTime.fromISO(this.menu.date.published || this.menu.date.modified).toLocal()
        : null;
    },
    enableTooltip() {
      return this.isLocked && !this.isActiveUser;
    },
    error() {
      return !this.menu || this.dateTime.invalid;
    },
    fullName() {
      return this.user && this.user.name
        ? `${this.user.name.first} ${this.user.name.last}`
        : 'unknown';
    },
    fuzzyDate() {
      return this.isToday
        ? `Today at ${this.dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
        : `${this.dateTime.toLocaleString()}
        at ${this.dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
    },
    icon() {
      return this.isLocked ? 'mdi-lock' : 'mdi-checkbox-blank-circle';
    },
    isActiveUser() {
      return this.active_user_id === (this.lockedByUser && this.lockedByUser.id);
    },
    isLastModifiedUser() {
      return this.active_user_id === (this.lastModifiedUser && this.lastModifiedUser.id);
    },
    isToday() {
      return this.dateTime.toRelativeCalendar() === 'today';
    },
    isUserLoading() {
      return this.user && this.usersMapNames[this.user] instanceof Promise;
    },
    lastModifiedUser() {
      const userId = this.menu && this.menu.meta && this.menu.meta.last_modified_user;
      if (!userId) return null;

      const user = this.usersMapNames[userId];
      return user && user.id ? user : null;
    },
    lockedByUser() {
      const userId = this.menu && this.menu.meta && this.menu.meta.locked_by_user;
      if (!userId) return null;

      const user = this.usersMapNames[userId];
      return user && user.id ? user : null;
    },
    menu() {
      return this.menus[this.menuId];
    },
    showLoading() {
      return this.error || this.isUserLoading || !this.menuId;
    },
    statusText() {
      if (this.error) return 'Last Published: Unknown';

      if (this.hideUser) {
        return `Last Published: ${this.fuzzyDate}`;
      }

      if (!this.isLocked) {
        return `Last Published: ${this.fuzzyDate}${
          this.user ? ` by ${this.isLastModifiedUser ? 'you' : this.fullName}` : ''
        }`;
      }

      if (!this.user) return 'Someone is editing this menu';

      return this.isActiveUser ? 'You are editing this menu' : `Editing: ${this.fullName}`;
    },
    tooltip() {
      return this.isLocked
        ? `${
            this.user ? this.fullName : 'Someone'
          } is editing the menu.\nYou can view the menu in read-only mode.`
        : '';
    },
    user() {
      return this.isLocked ? this.lockedByUser : this.lastModifiedUser;
    },
  },
  props: {
    menuId: {
      type: String,
    },
    hideUser: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
